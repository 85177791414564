import React, { Fragment } from 'react'
import StoreLocatorModal from '../common/StoreLocatorModal'
import ContactStoreModal from '../common/ContactStoreModal'
import StoreChangeModal from 'components/common/StoreChangeModal'
import SuccessfulSubmitModal from '../SuccessfulSubmitModal'
import FindLowerPriceModal from '../FindLowerPriceModal'
import AddVehicleModal from '../AddVehicleModal'
import VehicleConfiguratorModal from '../VehicleConfiguratorModal'
import { GlobalSearchModal } from '../GlobalSearchModal'
import { ConfirmYourVehicleModal, TireSizesNotFoundModal, TireConfirmationModal } from '../VehicleConfigurator'
import FindServiceHistoryModal from 'components/ServiceHistory/components/FindServiceHistoryModal'
import PromotionsDetailModalPage from '../PromotionsDetailModalPage'
import { AddToQuoteModal } from '../AddToQuoteModal'
import { PromotionLightBoxModal } from '../PromotionLightBoxModal'
import ShareViaEmailModal from '../ShareViaEmailModal'
import VehicleDeleteModal from '../MyGarage2.0/components/VehicleDeleteModal'
import { TirePromotionModal } from '../TireDetailsPage'
import ChangedAvailabilityPriceModal from '../ChangedAvailabilityPriceModal'
import { AppointmentUnsupportedModal } from '../Header'
import VehicleMergeModal from '../VehicleMergeModal'
import GenericMessageModal from '../GenericMessageModal'
import ForgotUsernameModal from '../Login/ForgotEmail/ForgotUsernameModal'
import ForgotPasswordModal from '../Login/ForgotPassword/ForgotPasswordModal'
import ContentModal from '../ContentModal'
import AppointmentSubmissionErrorModal from '../QuickAppointmentPage/Stepper/components/AppointmentSubmissionErrorModal'
import TPPModal from 'components/ShoppingQuote/BOTProductAndServicesQuote/components/TPPModal'
import CurbsideServiceModal from 'components/StoreDetailsPage/components/StoreInformation/components/CurbsideServiceModal'
import CustomPromotionModal from 'components/BOTPromotionBannerAndModal/CustomPromotionModal'
import VehiclePersonalizationModal from 'components/MyGarage2.0/components/VehiclePersonalizationModal'
import UpdateCancelInstructionsModal from 'components/MyAppointments/BOTMyAppointmentsDetails/components/UpdateCancelInstructionsModal'
import ServiceRecordModal from 'components/ServiceHistory/components/BOTServiceHistoryNew/components/ServiceRecordModal'
import BOTConnectToCarFax from 'components/ServiceHistory/components/BOTConnectToCarFax'
import RecordDeleteModal from 'components/ServiceHistory/components/BOTServiceHistoryNew/components/RecordDeleteModal'
import VehicleEditorModal from 'components/VehicleEditor/VehicleEditorModal'
import EditMileageModal from '../VehicleDetails/components/EditMileageModal'
import FleetCertifiedModal from 'components/StoreDetailsPage/components/StoreInformation/components/StoreDetails/components/FleetCertifiedModal'
import PromotionExpirationModal from 'components/ShoppingQuote/BOTProductAndServicesQuoteNew/components/PromotionExpirationModal'
import NewShopTiresModal from 'components/NewShopTiresModal'
import ActiveCartReminderModal from 'components/ActiveCartReminderModal'
import ArticlesAccordionModal from 'components/BOTArticleCategories/components/ArticlesAccordionModal'
import StoreNotEligibleForPromoModal from 'components/QuickAppointmentPage/ThreeStepper/components/StoreAndServices/StoreNotEligibleForPromoModal'

const ModalBoxList = () =>
  <Fragment>
    <AddVehicleModal />
    <VehicleConfiguratorModal />
    <SuccessfulSubmitModal />
    <FindLowerPriceModal />
    <GlobalSearchModal />
    <StoreLocatorModal />
    <StoreChangeModal />
    <ContactStoreModal />
    <ConfirmYourVehicleModal />
    <TireSizesNotFoundModal />
    <TireConfirmationModal />
    <FindServiceHistoryModal />
    <PromotionsDetailModalPage />
    <PromotionLightBoxModal />
    <AddToQuoteModal />
    <ShareViaEmailModal />
    <VehicleDeleteModal />
    <TirePromotionModal />
    <ChangedAvailabilityPriceModal />
    <AppointmentUnsupportedModal />
    <VehicleMergeModal />
    <GenericMessageModal />
    <ForgotUsernameModal />
    <ForgotPasswordModal />
    <ContentModal />
    <AppointmentSubmissionErrorModal />
    <TPPModal />
    <CurbsideServiceModal />
    <CustomPromotionModal />
    <VehiclePersonalizationModal />
    <UpdateCancelInstructionsModal />
    <ServiceRecordModal />
    <BOTConnectToCarFax />
    <RecordDeleteModal />
    <VehicleEditorModal />
    <EditMileageModal />
    <FleetCertifiedModal />
    <PromotionExpirationModal />
    <NewShopTiresModal />
    <ActiveCartReminderModal />
    <ArticlesAccordionModal />
    <StoreNotEligibleForPromoModal />
  </Fragment>

export default ModalBoxList
